import React from "react"
import { connector, ContainerProps } from "./container"
import { Heading } from "../../../components/Heading/Heading"
import Illustration from "../../../../../assets/lottie/05-secure.json"
import { FormattedMessage } from "../../../../../components/FormattedMessage/FormattedMessage"

export type Props = {}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div
      data-testid="signin/success"
      className="relative flex w-full h-full p-4 overflow-hidden shadow-inner"
    >
      <div className="w-full max-w-2xl mx-auto">
        <Heading
          big
          title={<FormattedMessage id="login.success.title" />}
          description={<FormattedMessage id="login.success.description" />}
          illustration={Illustration}
        ></Heading>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Success = connector(Container)
