import React from "react"
import Illustration from "../../../../../assets/lottie/mail.json"
import { Email } from "../../Email"
import { connector, ContainerProps } from "./container"
import { Heading } from "../../../components/Heading/Heading"
import { FormattedMessage } from "../../../../../components/FormattedMessage/FormattedMessage"

export type Props = {
  onNext: () => void
  onPrevious: () => void
  error: boolean
  isFocus: boolean
}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div className="relative flex w-full h-full">
      <div className="relative flex w-full h-full p-4 overflow-hidden shadow-inner">
        <div className="w-full max-w-2xl mx-auto">
          <Heading
            title={<FormattedMessage id="login.email.title" />}
            hide={props.error}
            shouldHideWhenSmall={props.isFocus}
            description={<FormattedMessage id="login.email.description" />}
            illustration={Illustration}
          />

          <div className="mt-4 sm:mt-10">
            <Email />
          </div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const EmailCompletion = connector(Container)
