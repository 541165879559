import { Transition } from "@headlessui/react"
import React from "react"
import { Wrapper as Confetti } from "../../../../../components/Confetti/Confetti"
import { BackgroundShapes } from "../../../components/BackgroundShapes"
import { SigninBottomBarAction as BottomBarAction } from "../../../components/BottomBarAction"
import { SigninCancel as Cancel } from "../../../components/Cancel"
import { ShadowLoader } from "../../../components/ShadowLoader"
import { EmailCompletion } from "../EmailCompletion"
import { Success } from "../Success"
import { Error } from "../Error"
import { connector, ContainerProps } from "./container"

export type Props = {
  lang: string
  error: string | null
  current: number
  loading: boolean
  focus: boolean
  success: boolean
  onNext: () => void
  onPrevious: () => void
}

const animations = {
  enter: "duration-1000",
  enterFrom: "opacity-0 -translate-y-10",
  enterTo: "opacity-1 translate-y-0",
  leave: "duration-300",
  leaveFrom: "opacity-1 translate-x-0",
  leaveTo: "opacity-0 -translate-x-52",
}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div className="w-full h-screen">
      <ShadowLoader show={props.loading} />

      <Cancel lang={props.lang} />

      <div className="relative z-10 flex w-full">
        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={!props.success && !props.error && props.current === 0}
          {...animations}
        >
          <EmailCompletion />
        </Transition>
        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={props.success}
          {...animations}
        >
          <Success />
        </Transition>

        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={!!props.error}
          {...animations}
        >
          <Error />
        </Transition>
      </div>

      <BottomBarAction />

      <BackgroundShapes step={props.current} error={!!props.error} />

      <Confetti isOpen={props.success} respawn={false} />
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const FullStepsRegistration = connector(Container)
