import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../../redux/actions"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState, props: { lang: string }) => ({
  error: state.auth.flows.signin.process.error,
  current: state.auth.flows.signin.steps.current,
  loading: state.auth.flows.signin.process.fetching,
  focus: state.auth.flows.signin.form.email.focus,
  success: state.auth.flows.signin.process.success,
  lang: props.lang,
})

const mapDispatch = (dispatch: any) => ({
  onNext: () => {
    dispatch(actions.auth.flows.signin.steps.fetchNext())
  },
  onPrevious: () => {
    dispatch(actions.auth.flows.signin.steps.previous())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
