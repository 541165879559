import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { FlowsSigninEmailStateCodesTypes } from "../../../../redux/auth/reducers"
import { ShadowInput } from "../../components/ShadowInput"
import { connector, ContainerProps } from "./container"

export type Props = {
  value: string
  error: boolean
  isFocus: boolean
  state: FlowsSigninEmailStateCodesTypes
  onChange: (value: string) => void
  onBlur: () => void
  onFocus: () => void
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const Wrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    return () => {
      props.onBlur()
    }
  }, [])

  return (
    <div className="relative">
      <div
        className={`relative z-20 ease-in-out transition-all duration-300 transform ${
          props.isFocus ? "translate-y-2" : "translate-y-0"
        }`}
      >
        <Transition
          className="absolute z-10 bottom-full left-0 right-0 transform w-full select-none transition-all duration-300 ease-in-out"
          show={props.error}
          enterFrom="translate-y-10 opacity-0"
          enterTo="translate-y-0 opacity-1"
          leaveFrom="translate-y-0 opacity-1"
          leaveTo="translate-y-10 opacity-0"
        >
          <div className="bg-red-100 border border-red-200 p-4 text-center text-red-400 rounded-sm mb-2">
            {props.state === "NOT_EXISTS" && (
              <>
                L'adresse email n'existe pas.
                <br />
                Vous pouvez créer un compte en cliquant{" "}
                <Link
                  to="/app/signup/"
                  className="font-bold underline cursor-pointer"
                >
                  cliquant ici
                </Link>
                .
              </>
            )}
            {props.state === "BAD_FORMED" && (
              <>Ce que vous avez entré ne semble pas être une adresse email.</>
            )}
          </div>
        </Transition>

        <div
          className={`relative z-20 ${props.error && "animate-headShakeOnce"}`}
        >
          <input
            className={`focus:ring-0 text-xl font-light appearance-none text-center relative block w-full py-4 border placeholder-slate-400 border-slate-200 rounded-sm text-slate-900 outline-none focus:outline-none focus:border-blue-300`}
            data-testid="signin/email/input"
            required
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            aria-label="Adresse email"
            value={props.value}
            onKeyPress={props.onKeyPress}
            name="email"
            type="email"
            autoComplete="off"
            placeholder="example@gmail.com"
            onChange={({ target }) => props.onChange(target.value)}
          />
          <div className="absolute z-10 right-0 top-0 h-full flex items-center pr-4">
            <svg
              className={`h-6 w-6 text-red-400 ${
                props.error ? "opacity-100" : "opacity-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <ShadowInput active={props.isFocus} />
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Email = connector(Container)
